<template>
    <span>
        <span v-html="displayedValue" /><span v-if="isCropped && !expand">&hellip;</span>
        <a
            v-if="isCropped && !expand && customFunction"
            @click.prevent="$emit('callback')">
            more
        </a>
        <a
            v-else-if="isCropped && !expand"
            @click.prevent="expand = true">
            more
        </a>
    </span>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        characters: {
            type: Number,
            default: 300
        },
        customFunction: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expand: false
        };
    },
    computed: {
        isCropped() {
            return (this.value.length > this.characters);
        },
        displayedValue() {
            // If the user has epanded this text or it's not long enough to crop return it
            if (this.expand || !this.isCropped) {
                return this.value;
            }

            // Otherwise return the cropped string
            return this.value.substring(0, this.characters);
        }
    }
};
</script>
